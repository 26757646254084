import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from 'src/components/page-header/page-header'
import { PageMaxWidth } from 'src/components/util-components/util-components'
import Panel from 'src/components/panel/panel'
import TextInput from 'src/components/input/input'
import Button from 'src/components/button/button'
import { Grid } from '@material-ui/core'
import EmptyState from 'src/components/empty-state/empty-state'
import * as styles from './player-points.module.less'
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table'
import { Body } from '../typography/typography'
import { SEARCH_PLAYERS } from '../players/players-queries'
import { meshGatewayClient } from 'src/apollo/client'
import { navigate } from 'gatsby'
import { useSectionAndDistricts } from 'src/hooks/use-section-and-districts'
import Dropdown from '../dropdown/dropdown'
import moment from 'moment'
import { CustomGrid } from '../custom-grid/custom-grid'
import {
  storePlayerPointsSeachFilter,
  retrievePlayerPointsSeachFilter
} from 'src/utils/storage/local-storage'
import {
  SearchPeople,
  SearchPeople_searchPeople_results as SearchPeopleResults
} from 'src/graphql-types/SearchPeople'
import { IconName } from '../icon/icon'
import { TFunction } from 'i18next'
import { useControlledQuery, transformSortDirection } from '../table-controls/table-controls'

type Filters = Partial<{
  section: { eq: string }
}> &
  Partial<{
    district: { eq: string }
  }>

interface GetEmptyStateComponentProps {
  search: string
  lastSearch: string
  t: TFunction
  results?: SearchPeopleResults[]
}

const getEmptyStateComponent = ({
  results,
  search,
  lastSearch,
  t
}: GetEmptyStateComponentProps) => {
  const isSearchUsed = search && lastSearch
  const data = {
    title: !results || !isSearchUsed ? t('start your search') : t('map no results', { search }),
    subtitle:
      !results || !isSearchUsed
        ? t('player points empty state subtitle')
        : t('update search criteria'),
    icon: 'xl-staff' as IconName
  }

  return <EmptyState {...data} />
}

const getControlledSearchPeopleQueryOptions = (search: string, filters: Filters): any => ({
  client: meshGatewayClient,
  getTotalItems: (d: SearchPeople) => d?.searchPeople?.total,
  awaitRefetchQueries: true,
  fetchPolicy: 'no-cahce',
  transformVariables: (v: any) => {
    const { sorts, limit, offset } = v

    if (!sorts)
      return {
        pageArgs: { limit, skip: offset },
        filter: { search: { normalized: search }, ...filters }
      }

    const [{ sortDirection, property }] = sorts
    return {
      pageArgs: { limit, skip: offset },
      sort: { field: property, direction: transformSortDirection(sortDirection) },
      filter: { search: { normalized: search }, ...filters }
    }
  }
})

export const PlayerPoints = () => {
  const { t } = useTranslation()
  const storedSearchFilter = useMemo(() => retrievePlayerPointsSeachFilter(), [])
  const [search, setSearch] = useState(storedSearchFilter ?? '')
  const [submittedSearch, setSubmittedSearch] = useState(search ?? '')
  const [lastResults, setLastResults] = useState<SearchPeopleResults[]>([])

  const [section, setSection] = useState<any>('')
  const [district, setDistrict] = useState<any>('')
  const { sectionOptions, districtOptions } = useSectionAndDistricts(section)

  // Store filter in local storage
  useEffect(() => {
    storePlayerPointsSeachFilter(search)
  }, [search])

  const filters: Filters = useMemo(
    () => ({
      ...(section?.value ? { section: { eq: section.label } } : {}),
      ...(district?.value ? { district: { eq: district.label } } : {})
    }),
    [section, district]
  )

  const { data, loading, refetch: refetchPlayers, controlProps } = useControlledQuery<SearchPeople>(
    SEARCH_PLAYERS,
    {
      ...getControlledSearchPeopleQueryOptions(search, filters),
      skip: !search || search !== submittedSearch
    }
  )

  const results = data?.searchPeople.results

  React.useEffect(() => {
    if (data) {
      const results = data.searchPeople?.results || []
      setLastResults(results)

      if ((lastResults.length || !storedSearchFilter) && results?.length === 1) {
        const [result] = results
        if (search) navigate(`/player-points/${result?.externalId}`)
      }
    }
  }, [data])

  const cols = useMemo<DataCols<any>>(
    () => [
      {
        key: 'name',
        title: t('name'),
        getValue: d => (
          <button
            className={styles.linkButton}
            onClick={() => navigate(`/player-points/${d?.externalId}`)}
          >
            {d.standardGivenName || ''} {d.standardFamilyName || ''}
          </button>
        )
      },
      {
        key: 'usta id',
        title: t('usta id'),
        getValue: d => d.externalId
      },
      {
        key: 'dateOfBirth',
        title: t('date of birth'),
        getValue: d =>
          t('member date', {
            date: moment(d.birthDate).local()
          })
      },
      {
        key: 'section',
        title: t('section'),
        getValue: d => d.section?.name || t('n/a')
      },
      {
        key: 'district',
        title: t('district'),
        getValue: d => d.district?.name || t('n/a')
      },
      {
        key: 'details',
        title: ' ',
        getValue: d => (
          <Button
            size="sm"
            level="tertiary"
            onClick={() => navigate(`/player-points/${d?.externalId}`)}
          >
            {t('view details')}
          </Button>
        )
      }
    ],
    [t, results]
  )

  async function handleSubmit() {
    setSubmittedSearch(search)
    await refetchPlayers()
  }

  const EmptyStateComponent = useMemo(
    () => getEmptyStateComponent({ results: lastResults, search, lastSearch: submittedSearch, t }),
    [submittedSearch, t]
  )

  return (
    <PageMaxWidth>
      <PageHeader title={t('player points')} />
      <Panel>
        <Grid container>
          <div className={styles.searchContainer}>
            <TextInput
              placeholder={t('search name usta id')}
              value={search}
              onChange={t => setSearch(t?.target.value)}
              fullWidth
              disableUnderline
              outlined
              onClearClick={search ? () => setSearch('') : undefined}
            />
          </div>
          <Button
            spacing={{ margins: { xs: 'left' } }}
            onClick={handleSubmit}
            loading={loading}
            disabled={!search}
          >
            {t('search players')}
          </Button>
        </Grid>
      </Panel>
      <Panel>
        {lastResults?.length && !loading && controlProps.totalItems !== undefined ? (
          <SearchResultsLabel
            numberOfResults={controlProps.totalItems}
            lastSearch={submittedSearch}
          />
        ) : null}
        <CustomGrid container spacing={{ margins: { sm: 'bottom' } }}>
          <Dropdown
            selected={section?.value ?? ''}
            options={sectionOptions ?? []}
            placeholder={t('section all')}
            onSelect={o => {
              setSection(o)
              setDistrict('') // Reset district on section change
            }}
            spacing={{ margins: { md: 'right' } }}
            disabled={!search}
          />
          <Dropdown
            selected={district?.value ?? ''}
            options={districtOptions ?? []}
            placeholder={t('district all')}
            onSelect={o => {
              setDistrict(o)
            }}
            disabled={!section?.value || !search}
          />
        </CustomGrid>
        <AdminTable
          columns={cols}
          data={lastResults as any}
          loading={loading}
          noDataComponent={EmptyStateComponent}
          controls={controlProps}
          hideTopPaginationInfo={search !== submittedSearch}
          filters={filters}
        />
      </Panel>
    </PageMaxWidth>
  )
}

const SearchResultsLabel = ({
  numberOfResults,
  lastSearch
}: {
  numberOfResults: number
  lastSearch: string
}) => {
  const verb = numberOfResults === 1 ? 'result' : 'results'

  if (!numberOfResults) return null

  return (
    <Body size="xxl" spacing={{ margins: { md: 'bottom' } }}>
      {numberOfResults} {verb} for <span className={styles.searchResultName}>'{lastSearch}'</span>
    </Body>
  )
}
