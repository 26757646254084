// extracted by mini-css-extract-plugin
export var bodyBase = "player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var bodyLarge = "player-points-module--body-large--SPIVM player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var bodyLargeBold = "player-points-module--body-large-bold--yXfQK player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var bodyRegular = "player-points-module--body-regular--o-Bqw player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var bodyRegularBold = "player-points-module--body-regular-bold--3eGuY player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var bodySmall = "player-points-module--body-small--Z5R8r player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var bodySmallBold = "player-points-module--body-small-bold--3oCZH player-points-module--body-base--tisZr player-points-module--site-font--9GhHR";
export var borderTop = "player-points-module--border-top--5I4Ph";
export var breakWordContainer = "player-points-module--break-word-container--PMgBC";
export var buttonIconBase = "player-points-module--button-icon-base--zWsEr";
export var clickLink = "player-points-module--click-link--apXb3";
export var dropdownBase = "player-points-module--dropdown-base--yfLze";
export var dropdownSelectBase = "player-points-module--dropdown-select-base--rl8JT player-points-module--text-input--VXpbG";
export var flexCol = "player-points-module--flex-col--SvP5D";
export var formErrorMessage = "player-points-module--form-error-message--uWlg2";
export var h3 = "player-points-module--h3--saV0T";
export var h4 = "player-points-module--h4---aWwO";
export var hoverLink = "player-points-module--hover-link--YMnpK";
export var hoverRow = "player-points-module--hover-row--wMJEx";
export var linkButton = "player-points-module--link-button--mqOqD player-points-module--unstyled-button--TgTIn";
export var membershipContainer = "player-points-module--membership-container--bKjef player-points-module--flex-col--SvP5D player-points-module--primary-border--40BDB";
export var membershipHeader = "player-points-module--membership-header--hsu94";
export var membershipHeading = "player-points-module--membership-heading--XviiL";
export var membershipLabel = "player-points-module--membership-label--xvpG+";
export var moreFiltersBorderClass = "player-points-module--more-filters-border-class--zrkrv";
export var pageBg = "player-points-module--page-bg--+9ItZ";
export var pointer = "player-points-module--pointer--lN8IB";
export var primaryBorder = "player-points-module--primary-border--40BDB";
export var searchContainer = "player-points-module--search-container--SObEB";
export var searchResultName = "player-points-module--search-result-name--NEQ6B";
export var shadowBoxLight = "player-points-module--shadow-box-light--QaaY+";
export var siteFont = "player-points-module--site-font--9GhHR";
export var slideDownAndFade = "player-points-module--slideDownAndFade--xx7uE";
export var slideLeftAndFade = "player-points-module--slideLeftAndFade--o6Gpz";
export var slideRightAndFade = "player-points-module--slideRightAndFade--TfZ-W";
export var slideUpAndFade = "player-points-module--slideUpAndFade--LY2AG";
export var statusDecoration = "player-points-module--status-decoration--CMVUt";
export var textInput = "player-points-module--text-input--VXpbG";
export var textInverted = "player-points-module--text-inverted--HoXfa";
export var textMediumDark = "player-points-module--text-medium-dark--GB5yF";
export var tooltipFont = "player-points-module--tooltipFont--mk2cE";
export var unstyledButton = "player-points-module--unstyled-button--TgTIn";