import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import { PlayerPoints } from 'src/components/player-points/player-points'

const PlayerPointsPage = () => {
  usePrivateRoute()
  return (
    <Layout>
        <SEO title="Player points" />
        <PlayerPoints />
    </Layout>
  )
}

export default PlayerPointsPage
